import React, {useState, useEffect} from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import FeaturedPost from "../components/FeaturedPost";
import styles from "../styles/blog-list.module.scss";
import { useLocation } from "react-use";

const Index = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;
  const [filteredPosts, setFilteredPosts] = useState(posts);
  const { search } = useLocation();

  useEffect(() => {
	  if (!!search && search.length > 0) {
		const params = new Proxy(new URLSearchParams(search), {
  			get: (searchParams, prop) => searchParams.get(prop),
		});
		const searchVal = params.search.toLowerCase();
	  setFilteredPosts(
	      posts.filter(
		  post => post.node.frontmatter.title.toLowerCase().includes(searchVal)
		      || post.node.excerpt.toLowerCase().includes(searchVal)
		      || post.node.frontmatter.category.toLowerCase().includes(searchVal)
	  ))
         }
	 else {
	     setFilteredPosts(posts);
	 }
  }, [search]);

  return (
    <Layout>
      <div className={styles.blogList}>
	      {filteredPosts.length === 0? "Não há posts com esse conteúdo" : filteredPosts
          .filter((post) => post.node.frontmatter.title.length > 0)
          .map(({ node: post }) => {
            return (
              <FeaturedPost
                post={post}
                className={styles.blogListPreview}
                key={post.id}
              />
            );
          })}
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { published: { eq: true } } }
      limit: 1000
    ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            category
            date(formatString: "DD/MM/YYYY")
            path
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Index;
